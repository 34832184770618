/**
 * Imports
 */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ProviderProps } from 'react-redux';

import { rootReducer } from './reducer';
// Firebase setup
// import userFeature from 'features/user';
// import { getFirebaseInstance, getUser } from 'firebaseAPI';

/**
 * Functions
 */
const storePromise: Promise<ProviderProps['store']> = new Promise(
  (resolve, reject) => {
    try {
      const store = configureStore({
        reducer: rootReducer,
        middleware: [
          ...getDefaultMiddleware({
            serializableCheck: false,
          }),
        ],
      });
      // TODO: uncomment this when firebase is setup
      // const firebase = getFirebaseInstance();
      // firebase.auth().onAuthStateChanged(async (authState) => {
      //   if (!authState) {
      //     console.log('User is not authenticated or logged out');
      //     store.dispatch(userFeature.redux.actions.logout({}));
      //   }
      //   if (authState) {
      //     const user = await getUser(authState.uid);
      //     console.log(user);
      //     if (user) {
      //       store.dispatch(userFeature.redux.actions.userUpdated(user));
      //       console.log({ key: user.id, id: user.id });
      //     }
      //     resolve(store);
      //   } else {
      //     resolve(store);
      //   }
      // });
      // TODO: when firebase is setup remove this and uncomment above
      resolve(store);
    } catch (error) {
      console.log('ERROR', error);
      reject(error);
    }
  },
);
/**
 * Redux store
 */
export { storePromise };
