/**
 * Imports
 */
import { Spinner, Text } from 'components';
import { BaseLayout } from 'features/navigation/components';
import { useReceipts } from 'features/receipts/api';
import {
  Link4PayReceipt,
  PaymentInformation,
  Receipt,
} from 'features/receipts/components';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router';
import { styled } from 'theme';

/**
 * Styling
 */
const Container = styled.div`
  padding: 24px;
`;

const NotFound = styled.div`
  background-color: ${({ theme }) => theme.Vibrant.Colors.Background.Primary};
  border-radius: 4px;
  padding: 48px;
  min-height: 534px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Types
 */
export interface ReceiptsPageProps {}

/**
 * ReceiptsPage component
 */
const ReceiptsPage: React.FC<ReceiptsPageProps> = (_props) => {
  const params = useParams<{ id: string }>();
  const { isLoading, receiptItem } = useReceipts(params.id);
  console.log('receiptItem', receiptItem);

  if (isLoading) {
    return (
      <BaseLayout>
        <Spinner showSpinner />
      </BaseLayout>
    );
  }
  if (!receiptItem && !isLoading) {
    return (
      <BaseLayout>
        <Container>
          <NotFound>
            <Text size="p1">
              <Trans
                i18nKey="Global.receipt_not_found"
                values={{ id: params.id }}
              >
                <Text size="p1" color="brand" bold />
              </Trans>
            </Text>
          </NotFound>
        </Container>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Container>
        {receiptItem && (
          <>
            {receiptItem?.merchantCountry === 'CY' ||
            receiptItem?.merchantCountry === 'GR' ? (
              <Link4PayReceipt receiptItem={receiptItem} />
            ) : (
              <Receipt receiptItem={receiptItem} />
            )}
            <PaymentInformation receiptItem={receiptItem} />
          </>
        )}
      </Container>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { ReceiptsPage };
