/**
 * Imports
 */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider, ProviderProps } from 'react-redux';

import { RootRouter } from 'router';
import { ThemeProvider } from 'theme';

import { Spinner } from 'components';

import reportWebVitals from './reportWebVitals';
// Sentry imports
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// Store setup
import { storePromise } from './store';
import { useTranslation } from 'react-i18next';
import './translations/config';
import './index.css';
import './styles/fonts/basier.css';
// Antd style overrides
import './styles/antd/less/antd-overrides.less';
import { PROJECT_CONFIG } from 'config';
import { BaseLayout } from 'features/navigation/components';

/**
 * Sentry setup
 */
Sentry.init({
  // enabled: PROJECT_CONFIG.environment === 'production',
  dsn: PROJECT_CONFIG.sentryDsn,
  environment: PROJECT_CONFIG.environment,
  integrations: [new Integrations.BrowserTracing()],
  release: PROJECT_CONFIG.gitRelease,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/**
 * `App` component
 */
const App = () => {
  const [store, setStore] = React.useState<ProviderProps['store'] | null>(null);
  useTranslation();
  React.useEffect(() => {
    storePromise
      .then((_store) => setStore(_store))
      .catch(() => console.log('Error occurred getting store ready'));
  }, []);

  if (!store) {
    return (
      <BaseLayout>
        <Spinner showSpinner />
      </BaseLayout>
    );
  }

  return (
    <React.StrictMode>
      <ThemeProvider>
        <Provider store={store}>
          <RootRouter />
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

/**
 * Entry point
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
