/**
 * Imports
 */
import { Text } from 'components';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { styled } from 'theme';
import { SoftPos } from 'types/softpos';

/**
 * Styling
 */
const Root = styled.div`
  padding: 48px;
  @media (max-width: 600px) {
    margin-top: 0;
    padding: 16px 24px;
  }
`;
const Grid = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  grid-gap: 12px;
`;

/**
 * Types
 */
export interface PaymentInformationProps {
  receiptItem: SoftPos.Receipt;
}

/**
 * PaymentInformation component
 */
const PaymentInformation: React.FC<PaymentInformationProps> = ({
  receiptItem,
}) => {
  return (
    <Root>
      <Text size="ui2" bold>
        <Trans i18nKey="Receipt.title" />
      </Text>
      <Grid>
        <Text size="ui3" color="ui">
          <Trans i18nKey="Receipt.card_type" />
        </Text>
        <Text size="ui2">{receiptItem?.cardType}</Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="Receipt.last_four" />
        </Text>
        <Text size="ui2">**** **** **** {receiptItem?.last4}</Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="Receipt.descriptor" />
        </Text>
        <Text size="ui2">{receiptItem?.descriptor}</Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="Receipt.terminal_id" />
        </Text>
        <Text size="ui2">{receiptItem?.terminalId}</Text>
        <Text size="ui3" color="ui">
          <Trans i18nKey="Receipt.trxn_id" />
        </Text>
        <Text size="ui2">{receiptItem?.trxnId}</Text>
      </Grid>
    </Root>
  );
};

/**
 * Exports
 */
export { PaymentInformation };
