/**
 * Imports
 */
import { Logo, Space, Text } from 'components';
import moment from 'moment';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { styled } from 'theme';
import { SoftPos } from 'types/softpos';
import { DATE_FORMATS, formatCurrency } from 'utils';
import logo from '../../../../assets/images/logo-link4pay.png';

/**
 * Styling
 */
const Root = styled.div`
  background-color: ${({ theme }) => theme.Vibrant.Colors.Background.Primary};
  border-radius: 4px;
  padding: 48px;
  min-height: 534px;

  @media (max-width: 600px) {
    padding: 16px 24px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ReceiptNumber = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    justify-content: flex-start;
    margin-bottom: 24px;
  }
`;
const RowMobile = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
  }
`;
const GridRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  justify-content: space-between;

  & div:nth-child(3n + 3) {
    text-align: right;
  }
`;
const Column = styled.div<{ rightAlign?: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: ${({ rightAlign }) => rightAlign && 'right'};
`;
const TitleRow = styled(GridRow)`
  margin: 64px 0 16px 0;
`;
const TrxnDataRow = styled(GridRow)`
  border-top: 1px solid
    ${({ theme }) => theme.Vibrant.Colors.Border.BorderTertiary};
  border-bottom: 1px solid
    ${({ theme }) => theme.Vibrant.Colors.Border.BorderTertiary};
  padding: 32px 0;
`;
const StyledImage = styled.img`
  height: 80px;
  margin-left: -16px;
  margin-top: -32px;
`;
/**
 * Types
 */
export interface Link4PayReceiptProps {
  receiptItem: SoftPos.Receipt;
}

/**
 * Link4PayReceipt component
 */
const Link4PayReceipt: React.FC<Link4PayReceiptProps> = ({ receiptItem }) => {
  return (
    <Root>
      <RowMobile>
        <Column>
          <Text size="h5">{receiptItem.merchantStoreName}</Text>
          <Space size="sm" />
          <Text size="ui2" color="ui">
            {receiptItem?.addressLine1}
          </Text>
          <Text size="ui2" color="ui">
            {receiptItem?.zip} {receiptItem.city}
          </Text>
          <Space size="xs" />
          <Text size="ui2" color="ui">
            <Trans i18nKey="Receipt.date" />:{' '}
            {receiptItem?.date &&
              moment(receiptItem?.date).format(DATE_FORMATS.time)}
          </Text>
          <Text size="ui2" color="ui">
            Registration Number: {receiptItem.registrationNumber}
          </Text>
        </Column>
        <ReceiptNumber>
          <Text size="ui2">
            <Trans i18nKey="Receipt.receiptNumber" />
            :&nbsp;
          </Text>
          <Text size="ui2">{receiptItem.receiptNumber}</Text>
        </ReceiptNumber>
      </RowMobile>
      <TitleRow>
        <Text size="ui3" bold color="ui">
          <Trans i18nKey="Receipt.product_name_title" />
        </Text>
        <Text size="ui3" bold color="ui">
          <Trans i18nKey="Receipt.quantity" />
        </Text>
        <Text size="ui3" bold color="ui">
          <Trans i18nKey="Receipt.amount" />
        </Text>
      </TitleRow>
      <TrxnDataRow>
        <Text size="ui2">{receiptItem?.merchantStoreName}</Text>
        <Text size="ui2">01</Text>
        <Text size="ui2">
          {receiptItem?.amount &&
            formatCurrency(receiptItem?.amount, receiptItem?.currency)}
        </Text>
      </TrxnDataRow>
      <Space size="sm" />
      <Row>
        <Column>
          <Text size="ui2" bold>
            <Trans i18nKey="Receipt.total" />
          </Text>
        </Column>
        <Column rightAlign>
          <Text size="ui2" bold>
            {receiptItem?.amount &&
              formatCurrency(receiptItem?.amount, receiptItem?.currency)}
          </Text>
        </Column>
      </Row>
      <Space size="xxl" />
      <Row>
        <StyledImage src={logo} alt="logo" />
      </Row>
    </Root>
  );
};

/**
 * Exports
 */
export { Link4PayReceipt };
