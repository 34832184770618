/**
 * imports
 */
import axios from 'axios';
import { PROJECT_API_URL } from 'config';
import { SoftPos } from 'types/softpos';
import { captureError } from 'utils/error-logging';

/**
 * Get a single transaction
 */
export const getReceipt = async (
  receiptId: string,
): Promise<SoftPos.Receipt> => {
  try {
    const response = await axios.get(
      `${PROJECT_API_URL}/public/softpos/v1/receipt/${receiptId}`,
    );
    return response.data.result;
  } catch (error) {
    captureError('Could not fetch receipt', error);
    throw new Error('Could not fetch receipt');
  }
};
