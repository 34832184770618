/**
 * Imports
 */
import { HomePage } from 'features/navigation/pages';
import { ReceiptsPage } from 'features/receipts/pages';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { styled } from 'theme';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.Vibrant.Colors.Background.White};

  height: 100%;
  min-height: 100%;
`;

/**
 * Types
 */
export interface RootRouterProps {}

export type RoutesType = 'receipts';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  pageName: string | React.ReactNode;
  component: React.FC;
  path: string;
  route: string;
  isPrivate: boolean;
  dontCheckAuth?: boolean;
};

/**
 * ROUTES
 */
export const ROUTES: RouteLookup = {
  receipts: {
    pageName: <Trans i18nKey="Breadcrumb.home" />,
    component: ReceiptsPage,
    path: '/',
    route: '/:id',
    isPrivate: false,
  },
};

/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  return (
    <BrowserRouter>
      <Root>
        <Switch>
          {Object.values(ROUTES).map((route) => {
            const RouteContainer = route.isPrivate ? PrivateRoute : PublicRoute;

            return (
              <RouteContainer
                exact
                key={route.route}
                path={route.route}
                component={route.component}
                dontCheckAuth={route.dontCheckAuth}
              />
            );
          })}

          <Route path="*" component={() => <h1>404</h1>} />
        </Switch>
      </Root>
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };
