/**
 * Imports
 */
import { getReceipt } from 'apis/receiptsAPI';
import * as React from 'react';
import { SoftPos } from 'types/softpos';

/**
 * Types
 */
export type ReceiptsAPIHandlers = {
  onGetReceipts: (id: string) => Promise<void>;
};
export type ReceiptsAPIType = {
  receiptItem: SoftPos.Receipt | undefined;
  handlers: ReceiptsAPIHandlers;
  isLoading: boolean;
};

/**
 * useReceipts
 */
const useReceipts = (id: string): ReceiptsAPIType => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [receiptItem, setReceiptItem] = React.useState<
    SoftPos.Receipt | undefined
  >();

  React.useEffect(() => {
    handlers.onGetReceipts(id);
  }, [id]);

  const handlers: ReceiptsAPIHandlers = {
    /**
     * onGetReceipts
     */
    onGetReceipts: async (id: string) => {
      try {
        setIsLoading(true);
        const resp = (await getReceipt(id)) as SoftPos.Receipt;
        setReceiptItem(resp);
        setIsLoading(false);
      } catch (exception) {
        setIsLoading(false);
        console.error(exception);
      }
    },
  };

  return { handlers, isLoading, receiptItem };
};

/**
 * Exports
 */
export { useReceipts };
