/**
 * Project API url
 */
const getprojectApiUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('dev')) {
    return 'https://api.dev.vibrant.one';
  }
  if (hostname.includes('uat')) {
    return 'https://api.uat.vibrant.one';
  }
  if (hostname.includes('receipt.vibrant.io')) {
    return 'https://api.vibrant.one';
  }
  /// should be used for localhost
  return 'https://api.vibrant.one';
  // return 'https://api.uat.vibrant.one';
  // return 'https://api.dev.vibrant.one';
  // return 'http://localhost:3002';
};
export const PROJECT_API_URL = getprojectApiUrl();
/**
 * Firebase environment settings
 */
const getFirebaseConfig = () => {
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      apiKey: 'AIzaSyDVDd4j87hxMN6VRnxKqV9Pv2ynjCDp_CI',
      authDomain: 'vibrant-fb-uat.firebaseapp.com',
      databaseURL: 'https://vibrant-fb-uat.firebaseio.com',
      projectId: 'vibrant-fb-uat',
      storageBucket: 'vibrant-fb-uat.appspot.com',
      messagingSenderId: '922162229168',
      appId: '1:922162229168:web:8ba120129183849f5a7075',
      measurementId: 'G-LWK7L7S6FW',
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://df75ed1925b94ca19b28b7a67f235e12@o551933.ingest.sentry.io/5789947',
    };
  }
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      apiKey: 'AIzaSyAbIbKSw_SmtYM8WLqs9ZUoFsb07q7i28o',
      authDomain: 'vibrantly-production.firebaseapp.com',
      databaseURL: 'https://vibrantly-production.firebaseio.com',
      projectId: 'vibrantly-production',
      storageBucket: 'vibrantly-production.appspot.com',
      messagingSenderId: '836686013166',
      appId: '1:836686013166:web:92e754601ffb7fd8c409ff',
      measurementId: 'G-LQQ1VK3LEY',
      environment: 'production',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://df75ed1925b94ca19b28b7a67f235e12@o551933.ingest.sentry.io/5789947',
    };
  }
  console.log('Running dev');
  return {
    apiKey: 'AIzaSyCv-etousHNny0jlIiLLkXopYTyBJbeBLY',
    authDomain: 'staging-727fe.firebaseapp.com',
    databaseURL: 'https://staging-727fe.firebaseio.com',
    projectId: 'staging-727fe',
    storageBucket: 'staging-727fe.appspot.com',
    messagingSenderId: '400993474174',
    appId: '1:400993474174:web:7f0020659577dc5bd42a2f',
    measurementId: 'G-6N775Q51RB',
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    sentryDsn:
      'https://df75ed1925b94ca19b28b7a67f235e12@o551933.ingest.sentry.io/5789947',
  };
};
export const FIREBASE_CONFIG = getFirebaseConfig();
/**
 * Project environment settings
 */
const getProjectConfig = () => {
  if (PROJECT_API_URL.includes('uat')) {
    console.log('Running uat');
    return {
      environment: 'uat',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://df75ed1925b94ca19b28b7a67f235e12@o551933.ingest.sentry.io/5789947',
    };
  }
  if (PROJECT_API_URL.includes('api.vibrant.one')) {
    console.log('Running production');
    return {
      environment: 'production',
      gitRelease: 'PENDING_1.0.0',
      sentryDsn:
        'https://df75ed1925b94ca19b28b7a67f235e12@o551933.ingest.sentry.io/5789947',
    };
  }
  console.log('Running dev');
  return {
    environment: 'localhost',
    gitRelease: 'PENDING_1.0.0',
    sentryDsn:
      'https://df75ed1925b94ca19b28b7a67f235e12@o551933.ingest.sentry.io/5789947',
  };
};
export const PROJECT_CONFIG = getProjectConfig();
